.iframe {
  margin: auto;
  display: block;
  height: 500px;
  width: calc(500px * 1.77);
}

@media screen and (max-width: 1000px) {
  .iframe {
    height: 400px;
    width: calc(400px * 1.77);
  }
}

@media screen and (max-width: 800px) {
  .iframe {
    height: 300px;
    width: calc(300px * 1.77);
  }
}

@media screen and (max-width: 600px) {
  .iframe {
    height: 200px;
    width: calc(200px * 1.77);
  }
}

@media screen and (max-width: 450px) {
  .iframe {
    height: 180px;
    width: calc(180px * 1.77);
  }
}