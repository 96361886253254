.container {
  background-color: #181818;
  min-height: 100vh;
  width: 101%;
}

.hero_image_container {
  height: 450px;
  background: url('../../../../assets/images/certificates/certificates-image.jpg') no-repeat right center/cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}

.hero_image_container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 37.5px;
  width: 100%;
  background-color: #181818;
  z-index: 0;
}

.input_container {
  max-width: 1280px;
  margin: 0 20px;
  padding: 10px 24px;
  height: 75px;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.input {
  padding: 0 20px 0 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  color: #474747;
}

.input input {
  flex: 1;
  height: auto;
  font-size: 18px;
  border: none;
  color: #474747;
}

.input input:focus {
  outline: none;
}

.input input::placeholder {
  color: rgba(71, 71, 71, 0.4);
}

.mobile_button {
  display: none;
}

.desktop_button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  width: 140px;
  background-color: #782DD4;
  border-radius: 8px;
  color: #fff;
}

.desktop_button:disabled {
  background-color: #cfcfcf;
  cursor: not-allowed;
}

@media screen and (max-width: 550px) {
  .hero_image_container {
    height: 350px;
  }

  .hero_image_container::after {
    height: 28px;
  }

  .input_container {
    height: 56px;
    padding: 0;
  }

  .input {
    padding: 0 20px;
  }

  .input input {
    font-size: 14px;
  }

  .desktop_button,
  .count,
  .search_icon {
    display: none;
  }

  .mobile_button {
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 100%;
    width: 65px;
    background-color: #782DD4;
    border-radius: 0 8px 8px 0;
    color: #fff;
  }
}