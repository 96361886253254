.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  background: linear-gradient(
    to right,
    rgba(120, 45, 212, 0.3) 50%,
    #f4f5fa 50%
  );
}

.video {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.video iframe {
  display: block;
  height: 500px;
  width: calc(500px * 1.77);
}

.video::before,
.video::after {
  content: '';
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
}

.video::after {
  top: -10px;
  left: -10px;
  border-top: 1px solid #782dd4;
  border-left: 1px solid #782dd4;
}

.video::before {
  bottom: -10px;
  right: -10px;
  border-bottom: 1px solid #782dd4;
  border-right: 1px solid #782dd4;
}

@media screen and (max-width: 1000px) {
  .container {
    height: 500px;
  }

  .video iframe {
    height: 400px;
    width: calc(400px * 1.77);
  }
}

@media screen and (max-width: 800px) {
  .container {
    height: 400px;
    margin-bottom: 50px;
  }

  .video iframe {
    height: 300px;
    width: calc(300px * 1.77);
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: 300px;
  }

  .video iframe {
    height: 200px;
    width: calc(200px * 1.77);
  }
}

@media screen and (max-width: 450px) {
  .container {
    height: 250px;
  }

  .video iframe {
    height: 180px;
    width: calc(180px * 1.77);
  }

  .video::before,
  .video::after {
    display: none;
  }
}
