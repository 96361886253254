.spinner_btn {
  width: 200px;
  background-color: #782DD4;
  color: white;
  height: 45px;
  border-radius: 5px;
  border: none;
}

.spinner_btn:hover {
  background-color: rgba(120, 45, 212, 0.7);
}

.spinner_btn:disabled {
  background: none;
  cursor: not-allowed;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fab_button {
  display: flex;
  position: sticky;
  width: 7vh;
  height: 7vh;
  bottom: 5vh;
  left: 95vw;
  background: none;
  color: #FFF !important;
  border-radius: 50%;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  margin: 30px 0px;
  text-decoration: none;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
}

.fab_button img {
  width: 60%;
}

@media screen and (max-width: 1100px) {
  .fab_button { 
    left: 90vw;
  }
}

@media screen and (max-width: 700px) {
  .fab_button { 
    left: 80vw;
  }
}