.container {
  max-width: 1320px;
  margin: 60px auto;
  padding: 0 20px;
}

.title {
  color: #782DD4;
  font-size: 40px;
}

.questions_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 36px;
}

.question {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 32px;
  align-items: center;
  gap: 24px;
  border-radius: 16px;
  background-color: #782DD4;
  color: #fff;
  cursor: pointer;
}

.question p {
  font-size: 24px;
  font-weight: 700;
  flex: 1 0 0;
  line-height: normal;
  font-style: normal;
  margin: 0;
}

.opened {
  border-radius: 16px 16px 0 0;
}

.opened .icon {
  transform: rotate(180deg);
}

.answer {
  background-color: #fff;
  color: #474747;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 32px;
  border-radius: 0 0 16px 16px;
  gap: 16px;
}

.answer p {
  font-size: 24px;
  font-weight: 400;
  flex: 1 0 0;
  line-height: normal;
  font-style: normal;
  margin: 0;
}

.answer span {
  font-weight: 700;
  display: block;
}

.answer ul li {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

@media (max-width: 850px) {
  .title {
    font-size: 24px;
  }

  .question p {
    font-size: 16px;
  }

  .answer p {
    font-size: 16px;
  }

  .answer ul li {
    font-size: 16px;
  }

  .container {
    margin-top: 0;
  }
}