.case_half_background {
  background: linear-gradient(
    to right,
    rgba(120, 45, 212, 1) 40%,
    #181818 40%
  );
}

.case_full_background {
  background: #181818;
  min-height: 100vh;
}

.full_container {
  overflow: scroll;
  height: 100vh;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.full {
  height: 100vh;
  scroll-snap-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose_grid_background {
  width: 100%;
  position: absolute;
  top: 130px;
  left: 0;
  z-index: 1;
}

.choose_grid_container {
  box-sizing: border-box;
  max-width: 1320px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}

.grid_button {
  background: none;
  border: none;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}

.active {
  border-bottom: 2px solid #782dd4;
}

.full .case,
.grid_container {
  color: #fff;
  display: flex;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 20px;
}

.full .case {
  height: 100vh;
  align-items: center;
}

.grid_container {
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 200px;
}

.grid .case {
  flex-direction: column;
  max-width: 380px;
  margin-bottom: 60px;
}

.full .content {
  position: relative;
}

.logo_image {
  position: absolute;
  top: -130px;
  right: 0;
}

.full .case_image {
  display: block;
  width: 60%;
}

.full .case_image img {
  width: 90%
}

.grid .case_image img {
  border-radius: 8px;
  width: 380px;
}

.full .content {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.full .title a {
  display: none;
}

.full .title,
.full .description {
  width: 80%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}

.full .title a {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.full .title > span {
  font-size: 1.5rem;
  font-weight: normal;
  color: #782dd4;
}

.grid .title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
}
.grid .title a {
  color: #fff;
}

.grid .title a:hover {
  color: #fff;
}

.grid .title > span {
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 8px;
  color: #a4a4a4;
}

.grid .description {
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 8px;
}

.full .see_more {
  display: flex;
  width: 150px;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid #782dd4;
  background: #782dd4;
  margin-bottom: 30px;
}

.full .see_more:hover {
  background: #fff;
  color: #782dd4;
}

.full .arrow {
  align-self: center;
}

.grid .see_more,
.grid .arrow {
  display: none;
}

.mobile_image {
  display: none;
}

@media screen and (max-width: 1150px) {
  .logo_image {
    top: -30%;
  }
}

@media screen and (max-width: 800px) {
  .case {
    position: relative;
  }

  .content {
    padding-bottom: 200px;
  }

  .title span {
    display: none;
  }

  .mobile_image {
    position: absolute;
    display: flex;
    justify-content: end;
    bottom: 0;
    right: 0;
    z-index: 0;
  }

  .mobile_image img {
    width: 100%;
  }

  .arrow {
    display: none;
  }

  .choose_grid_background {
    display: none;
  }

  .logo_image {
    left: 0;
    top: -150px;
  }

  .logo_image img {
    width: 80vw;
  }

  .full .case {
    padding-top: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .full .title,
  .full .description {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
  }

  .full .case_image {
    display: none;
  }

  .full .content {
    width: 100%;
    margin-left: 0;
    align-self: flex-start;
  }

  .full .description {
    width: 70%;
    z-index: 1;
  }

  .full .arrow {
    align-self: flex-start;
  }
}

@media screen and (max-width: 1200px) {
  .grid .case {
    max-width: 450px;
  }

  .grid .case_image img {
    width: 100%;
  }
}

@media screen and (max-width: 950px) {
  .grid .case {
    max-width: 100%;
  }

  .grid .case_image img {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .logo_image {
    top: -30%;
  }
}
