.container {
  display: flex;
  align-items: center;
  padding: 3vh 0;
  background-color: #782DD4;
  gap: 200px;
  flex-wrap: wrap-reverse;
}

.logo img {
  height: 600px;
}

.text {
  max-width: 700px;
  padding: 0px 20px;
}

.text h3 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 20px;
}

.text a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  background-color: #fff;
  border-radius: 8px;
  color: #782DD4;
  font-weight: 700;
  text-decoration: none;
  font-size: 16px;
}

@media (max-width: 1300px) {
  .container {
    gap: 50px;
  }

  .logo img {
    height: 250px;
  }

  .text {
    max-width: 500px;
  }

  .text h3 {
    font-size: 32px;
  }
}
