.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.image {
  margin-bottom: 36px;
}

.description {
  font-size: 22px;
  color: #fff;
  max-width: 580px;
  text-align: center;
  margin: 0 20px;
}

@media (max-width: 550px) {
  .container {
    margin-top: 30px;
  }

  .image {
    width: 60px;
  }

  .description {
    font-size: 14px;
    max-width: 300px;
  }
}