.container {
  max-width: 1320px;
  padding: 100px 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90vh;
}

.image {
  height: 100%;
}

.desc {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  max-width: 700px;
  color: #782dd4;
}

.paragraph {
  max-width: 700px;
  color: #474747;
  font-size: 1.5rem;
}

.image {
  width: 40%;
}

.image > img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .title {
    font-size: 2rem;
  }
  .paragraph {
    font-size: 1rem;
  }
}

@media screen and (max-width: 750px) {
  .container {
    flex-direction: column;
    height: 100%;
    padding: 50px 20px;
  }
  .image {
    height: 400px;
    width: 100%;
    order: 1;
    margin-bottom: 30px;
  }
  .desc {
    width: 100%;
    order: 2;
  }
}
