.container {
  max-width: 1320px;
  margin: 80px auto 0 auto;
  padding: 0 20px;
  display: flex;
}

.certificate_preview {
  width: 350px;
  height: 265px;
  background-size: cover;
  border-radius: 8px;
  margin-right: 50px;
}

.certificate_infos {
  display: flex;
  flex-direction: column;
  color: #fff;
}

.project_name {
  font-size: 24px;
  font-weight: bold;
}

.project_year {
  margin-top: 8px;
  font-size: 14px;
  color: #a4a4a4;
}

.project_description {
  margin-top: 16px;
  font-size: 14px;
  max-width: 350px;
}

.download_button {
  margin-top: 16px;
  border: none;
  background: #782DD4;
  color: #fff;
  padding: 8px 40px;
  border-radius: 8px;
  width: 160px;
  cursor: pointer;
}

.download_button:hover {
  background: #5C1E9C;
  color: #fff;
}

@media screen and (max-width: 550px) {
  .container {
    flex-direction: column;
    align-items: center;
    margin: 40px 0 0;
  }

  .certificate_preview {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .certificate_infos {
    margin: 12px 0;
    width: 100%;
  }

  .download_button {
    order: 1;
    margin-bottom: 16px;
  }

  .certificate_infos div {
    order: 2;
  }
}
